import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import ListIcon from "@material-ui/icons/List";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
// import ContactSupportIcon from "@material-ui/icons/ContactSupport";
// import GroupIcon from "@material-ui/icons/Group";
// import SendIcon from "@material-ui/icons/Send";
// import AccountBoxIcon from "@material-ui/icons/AccountBox";
// import GroupAddIcon from "@material-ui/icons/GroupAdd";

import { SidebarNav } from "./components";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { locationId } = jwt_decode(user.accessToken);

  const pages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Appointment Requests",
      href: "/agent",
      icon: <HeadsetMicIcon />,
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <SettingsIcon />,
    },
    // {
    //   title: "Support",
    //   href: "/support",
    //   icon: <ContactSupportIcon />,
    // },
  ];

  // update to 3 for KOC
  if (locationId === 3) {
    pages.push({
      title: "AI Call History",
      href: "https://app2.nextdrvisit.com/dashboard",
      icon: <ListIcon />,
    });
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
